export const themes = {
    default: {
        backgroundColor: 'var(--color-bg-button-default)',
        color: 'var(--color-text-button-default)',
        borderColor: 'transparent',
        transitionDuration: 'var(--transition-all-button-default)',
        iconColor: 'var(--color-icon-button-default)',
        underlineColor: 'transparent',
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-default-hover)',
            color: 'var(--color-text-button-default-hover)',
            iconColor: 'var(--color-icon-button-default-hover)',
            underlineColor: 'transparent',
        },
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-default-active)',
            color: 'var(--color-text-button-default-active)',
            borderColor: 'var(--color-border-button-default-active)',
            iconColor: 'var(--color-icon-button-default-active)',
            underlineColor: 'transparent',
        },
        disabledStyles: {
            backgroundColor: 'transparent',
            color: 'var(--color-text-button-default-disabled)',
            cursor: 'not-allowed',
            borderColor: 'var(--color-bg-button-default)',
            iconColor: 'var(--color-icon-button-default-disabled)',
            underlineColor: 'transparent',
        },
    },
    inverted: {
        backgroundColor: 'var(--color-bg-button-inverted)',
        color: 'var(--color-text-button-inverted)',
        borderColor: 'transparent',
        transitionDuration: 'var(--transition-all-button-inverted)',
        iconColor: 'var(--color-icon-button-inverted)',
        underlineColor: 'transparent',
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-inverted-hover)',
            color: 'var(--color-text-button-inverted-hover)',
            iconColor: 'var(--color-icon-button-inverted-hover)',
            underlineColor: 'transparent',
        },
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-inverted-active)',
            color: 'var(--color-text-button-inverted-active)',
            iconColor: 'var(--color-icon-button-inverted-active)',
            underlineColor: 'transparent',
        },
        disabledStyles: {
            backgroundColor: 'transparent',
            color: 'var(--color-text-button-inverted-disabled)',
            cursor: 'not-allowed',
            borderColor: 'var(--color-bg-button-inverted)',
            iconColor: 'var(--color-icon-button-inverted-disabled)',
            underlineColor: 'transparent',
        },
    },
    outlined: {
        backgroundColor: 'var(--color-bg-button-outlined)',
        color: 'var(--color-text-button-outlined)',
        borderColor: 'var(--color-border-button-outlined)',
        transitionDuration: 'var(--transition-all-button-outlined)',
        iconColor: 'var(--color-icon-button-outlined)',
        underlineColor: 'transparent',
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-outlined-hover)',
            color: 'var(--color-text-button-outlined-hover)',
            borderColor: 'transparent',
            iconColor: 'var(--color-icon-button-outlined-hover)',
            underlineColor: 'transparent',
        },
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-outlined-active)',
            color: 'var(--color-text-button-outlined-active)',
            borderColor: 'var(--color-border-button-outlined-active)',
            iconColor: 'var(--color-icon-button-outlined-active)',
            underlineColor: 'transparent',
        },
        disabledStyles: {
            backgroundColor: 'transparent',
            color: 'var(--color-text-button-outlined-disabled)',
            cursor: 'not-allowed',
            borderColor: 'var(--color-bg-button-outlined)',
            iconColor: 'var(--color-icon-button-outlined-disabled)',
            underlineColor: 'transparent',
        },
    },
    pagination: {
        backgroundColor: 'var(--color-bg-button-pagination)',
        color: 'var(--color-text-button-pagination)',
        borderColor: 'var(--color-border-button-pagination)',
        transitionDuration: 'var(--transition-all-button-pagination)',
        iconColor: 'var(--color-icon-button-pagination)',
        underlineColor: 'transparent',
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-pagination-hover)',
            color: 'var(--color-text-button-pagination-hover)',
            iconColor: 'var(--color-icon-button-pagination-hover)',
            underlineColor: 'transparent',
        },
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-pagination-active)',
            color: 'var(--color-text-button-pagination-active)',
            borderColor: 'var(--color-border-button-pagination-active)',
            iconColor: 'var(--color-icon-button-pagination-active)',
            underlineColor: 'transparent',
        },
        disabledStyles: {
            backgroundColor: 'transparent',
            color: 'var(--color-text-button-pagination-disabled)',
            cursor: 'not-allowed',
            borderColor: 'var(--color-bg-button-pagination)',
            iconColor: 'var(--color-icon-button-pagination-disabled)',
            underlineColor: 'transparent',
        },
    },
    defaultsecondary: {
        backgroundColor: 'transparent',
        color: 'var(--color-text-button-defaultsecondary)',
        borderColor: 'transparent',
        underlineColor: 'var(--color-underline-button-defaultsecondary)',
        transitionDuration: 'var(--transition-all-button-defaultsecondary)',
        iconColor: 'var(--color-icon-button-defaultsecondary)',
        hoverStyles: {
            backgroundColor: 'transparent',
            color: 'var(--color-text-button-defaultsecondary-hover)',
            underlineColor: 'transparent',
            borderColor: 'transparent',
            iconColor: 'var(--color-icon-button-defaultsecondary-hover)',
        },
        activeStyles: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            color: 'var(--color-text-button-defaultsecondary-active)',
            underlineColor: 'var(--color-underline-button-defaultsecondary-active)',
            iconColor: 'var(--color-icon-button-defaultsecondary-active)',
        },
        disabledStyles: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            color: 'var(--color-text-button-defaultsecondary-disabled)',
            cursor: 'not-allowed',
            underlineColor: 'var(--color-underline-button-defaultsecondary-disabled)',
            iconColor: 'var(--color-icon-button-defaultsecondary-disabled)',
        },
    },
    invertedsecondary: {
        backgroundColor: 'transparent',
        color: 'var(--color-text-button-invertedsecondary)',
        borderColor: 'transparent',
        underlineColor: 'var(--color-underline-button-invertedsecondary)',
        transitionDuration: 'var(--transition-all-button-invertedsecondary)',
        hoverStyles: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            color: 'var(--color-text-button-invertedsecondary-hover)',
            underlineColor: 'var(--color-underline-button-invertedsecondary-hover)',
        },
        activeStyles: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            color: 'var(--color-text-button-invertedsecondary-active)',
            underlineColor: 'var(--color-underline-button-invertedsecondary-active)',
        },
        disabledStyles: {
            backgroundColor: 'transparent',
            color: 'var(--color-text-button-invertedsecondary-disabled)',
            borderColor: 'transparent',
            cursor: 'not-allowed',
            underlineColor: 'var(--color-underline-button-invertedsecondary-disabled)',
        },
    },
    navigation: {
        backgroundColor: 'var(--color-bg-button-navigation)',
        color: 'var(--color-text-button-navigation)',
        borderColor: 'transparent',
        transitionDuration: 'var(--transition-all-button-navigation)',
        iconColor: 'var(--color-icon-button-navigation)',
        underlineColor: 'transparent',
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-navigation-hover)',
            color: 'var(--color-text-button-navigation-hover)',
            iconColor: 'var(--color-icon-button-navigation-hover)',
            underlineColor: 'transparent',
        },
        activeStyles: {
            backgroundColor: 'var(--color-bg-button-navigation-active)',
            color: 'var(--color-text-button-navigation-active)',
            borderColor: 'transparent',
            iconColor: 'var(--color-icon-button-navigation-active)',
            underlineColor: 'transparent',
        },
        disabledStyles: {
            backgroundColor: 'transparent',
            color: 'var(--color-text-button-navigation-disabled)',
            cursor: 'not-allowed',
            borderColor: 'var(--color-bg-button-navigation)',
            iconColor: 'var(--color-icon-button-navigation-disabled)',
            underlineColor: 'transparent',
        },
    },

    link: {
        color: 'var(--color-text-button-link)',
        borderColor: 'transparent',
        hoverStyles: {
            color: 'var(--color-bg-button-link-hover)',
        },
        activeStyles: {
            color: 'var(--color-text-button-link-active)',
            borderColor: 'transparent',
        },
    },

    variation: {
        backgroundColor: 'var(--color-bg-button-variation)',
        color: 'var(--color-text-button-variation)',
        borderColor: 'transparent',
        transitionDuration: 'var(--transition-all-button-variation)',
        underlineColor: 'transparent',
        hoverStyles: {
            backgroundColor: 'var(--color-bg-button-variation-hover)',
            color: 'var(--color-text-button-variation-hover)',
            iconColor: 'var(--color-icon-button-variation-hover)',
        },
        activeStyles: {
            color: 'var(--color-text-button-variation-active)',
            borderColor: 'transparent',
            backgroundColor: 'var(--color-bg-button-variation-active)',
        },
        disabledStyles: {
            backgroundColor: 'var(--color-text-button-variation-disabled)',
            color: 'var(--color-bg-button-variation-disabled)',
            cursor: 'not-allowed',
        },
    },
};

// Missing the size? Use $style to add custom styling.
export const sizes = {
    // Secondary
    sm: {
        padding: ['12px', null, null, null, '12px 16px'],
    },
    // Primary
    md: {
        padding: '12px 16px',
    },
};

export const themeNames: { [key: string]: string } = {
    // CMS names
    white: 'default',
    orange: 'inverted',
    white_outlined: 'outlined',
};

export const getButtonTheme = (theme = 'default'): string => {
    if (!theme) {
        return 'default';
    }

    return themeNames[theme] || theme;
};
