import PropTypes from 'prop-types';
import useFooterQuery from 'queries/useFooterQuery';
import FooterCore from '@activebrands/core-web/components/Footer';
import FooterBottom from 'components/Footer/FooterBottom';
import FooterMenu from 'components/Footer/FooterMenu';

const Footer = ({ alternateHrefs = [], previewDataOverride }) => {
    const content = previewDataOverride || useFooterQuery();

    const {
        items,
        featuredLinks,
        featuredLinksLabel,
        informationHeading,
        informationParagraphs,
        footerContent,
        paymentIcons,
        copywriteText,
    } = content;

    return (
        <FooterCore footerContent={footerContent}>
            <FooterMenu
                featuredLinks={featuredLinks}
                featuredLinksLabel={featuredLinksLabel}
                informationHeading={informationHeading}
                informationParagraphs={informationParagraphs}
                items={items || []}
            />
            <FooterBottom alternateHrefs={alternateHrefs} copywriteText={copywriteText} paymentIcons={paymentIcons} />
        </FooterCore>
    );
};

Footer.propTypes = {
    alternateHrefs: PropTypes.array,
    previewDataOverride: PropTypes.object,
};

export default Footer;
