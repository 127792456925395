import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import BackgroundVideo from '@activebrands/core-web/components/BackgroundVideo';
import Image from '@activebrands/core-web/components/Image';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import Events from '@activebrands/core-web/libs/Events';
import { TrackingLists } from '@activebrands/core-web/libs/Tracking/constants';
import { SearchEvents } from '@activebrands/core-web/libs/algolia/searchInsights';
import RichText, { RichTextPropType } from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import Heading from 'components/text/Heading';
import StateButton from './buttons/StateButton';
import Paragraph from './text/Paragraph';

const EntryText = styled('div', {
    display: '-webkit-box',
    height: 'auto',
    overflow: 'hidden',
    marginBottom: '32px',
    '-webkit-box-orient': 'vertical',

    [media.min['tablet.sm']]: {
        marginBottom: '24px',
    },
});

const Info = styled('div', {
    border: '1px solid var(--color-border-article-card)',
    borderTop: 'none',
    padding: '16px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [media.min['tablet.sm']]: {
        height: 'auto',
        width: '50%',
        border: '1px solid var(--color-border-article-card)',
        borderLeft: 'none',
    },

    [media.min['desktop.sm']]: {
        paddingRight: '16px',
    },
});

const Categories = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    width: '75%',
    color: 'var(--color-text-article-tag)',
    margin: '0 16px 0 0',
});

const ArticleCard = ({
    $style = {},
    categories = [],
    entryText,
    headingType = 'h3',
    imageSettings = {},
    media = {},
    maxRows = 3,
    objectID,
    position,
    publishedAt,
    searchMeta,
    title,
    to,
}) => {
    const [css] = useStyletron();
    const { width, sizes } = imageSettings;
    const published = publishedAt?.split('T')[0].replaceAll('-', '.').slice(2);
    const handleClick = () => {
        if (searchMeta) {
            Events.trigger(SearchEvents.CLICK, {
                ...searchMeta,
                objectIDs: [objectID],
                positions: [position],
                eventName: `${TrackingLists.PRODUCT_GRID}: Search`,
            });
        }
    };

    return (
        <div
            className={css({
                position: 'relative',
                display: 'flex',
                flexDirection: ['column', null, null, 'row'],
                width: '100%',
                height: '100%',
                ...$style,
            })}
            onClick={handleClick}
        >
            <div className={css({ width: ['100%', null, null, '50%'], height: '100%' })}>
                {media && (
                    <Link to={to}>
                        <div
                            className={css({
                                aspectRatio: 'var(--ratio-horizontal)',
                            })}
                        >
                            {media.type === 'video' ? (
                                <BackgroundVideo $style={{ position: 'relative' }} src={media.src} />
                            ) : (
                                <Image sizes={sizes} src={{ url: media.src, width }} />
                            )}
                        </div>
                    </Link>
                )}
            </div>
            <Info>
                <div>
                    <div className={css({ display: 'flex', justifyContent: 'space-between' })}>
                        {!!categories && (
                            <Categories>
                                {categories?.map((category, index) => (
                                    <Paragraph
                                        $style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                        fontKeys="Primary/14_100_-2_500"
                                        key={`${category.name}_${index}`}
                                    >
                                        {category.name}
                                    </Paragraph>
                                ))}
                            </Categories>
                        )}
                        {published && (
                            <Paragraph fontKeys="Primary/14_100_-3" $style={{ whiteSpace: 'nowrap' }}>
                                {published}
                            </Paragraph>
                        )}
                    </div>
                    <Link to={to}>
                        <Heading
                            $style={{ margin: published ? ['16px 0', null, '16px 0 24px'] : '16px 0 8px' }}
                            as={headingType}
                            fontKeys={['Primary/18_120_-05', null, null, 'Primary/24_120_-1']}
                        >
                            {title}
                        </Heading>
                    </Link>
                </div>
                <div>
                    {entryText && (
                        <EntryText
                            $style={{
                                '-webkit-line-clamp': maxRows,
                                ...textStyles['Primary/14_140_-2'],
                            }}
                        >
                            <RichText data={entryText} />
                        </EntryText>
                    )}

                    <Link to={to}>
                        <StateButton $style={{ padding: 'unset', gap: '8px' }} theme="defaultsecondary">
                            {fm('Read more')}
                        </StateButton>
                    </Link>
                </div>
            </Info>
        </div>
    );
};

ArticleCard.propTypes = {
    $style: PropTypes.object,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
        })
    ),
    entryText: RichTextPropType,
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    imageSettings: PropTypes.object,
    maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    media: PropTypes.shape({
        alt: PropTypes.string,
        hostedBy: PropTypes.string,
        position: PropTypes.string,
        size: PropTypes.string,
        src: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
    }),
    objectID: PropTypes.string,
    position: PropTypes.number,
    publishedAt: PropTypes.string,
    searchMeta: PropTypes.object,
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
};

export default ArticleCard;
